@font-face {
    font-family: firacode;
    src: url(./fonts/FiraCode-Medium.ttf);
    font-display: block;
    font-weight: normal;
}

@font-face {
    font-family: firacode;
    src: url(./fonts/FiraCode-SemiBold.ttf);
    font-display: block;
    font-weight: bold;
}

html {
    background-color: black;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

body {
    margin: 0;
    font-family: firacode, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
